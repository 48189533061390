import { Footer } from "./components/footer";
import { About } from "./sections/about";
import { Apps } from "./sections/apps";
import { Evolution } from "./sections/evolution";
import { Header } from "./sections/header";
import { Offer } from "./sections/offer";
import { Possibilities } from "./sections/possibilities";
import { Services } from "./sections/services";
import whatsapp from "./assets/whatsapp.svg";
function App() {
  return (
    <div>
      <div className="fixed bottom-[3rem] left-[calc(100%-0rem-64px)] md:bottom-[13rem]  md:left-[calc(100%-4rem-64px)] lg:bottom-[16rem] xl:bottom-[26rem] 2xl:bottom-[5rem] lg:left-[calc(100%-6rem-64px)] xl:left-[calc(100%-13rem-64px)] z-[2]">
        <a href="https://wa.me/5551995197234" target="_blank">
          <button className="h-12 w-h-12">
            <img src={whatsapp} className="block w-full h-full" alt="..." />
          </button>
        </a>
      </div>
      <Header />
      <Evolution />
      <Offer />
      <About />
      <Apps />
      <Services />
      <Possibilities />
      <Footer />
    </div>
  );
}

export default App;
