import aboutImg from "../../assets/about.webp";
import bank from "../../assets/bank.svg";
import lock from "../../assets/lock.svg";
import check from "../../assets/check.svg";
import share from "../../assets/share.svg";
const data = [
  {
    img: bank,
    title: "Confiança",
    description: "Operação autorizada pela Resolução CVM nº 88/2022.",
  },
  {
    img: share,
    title: "Agilidade",
    description: "Análise criteriosa dos projetos selecionados.",
  },
  {
    img: lock,
    title: "Diferencial",
    description:
      "Alta rentabilidade anual, acima das rendas fixas tradicionais.",
  },
  {
    img: check,
    title: "Comprometimento",
    description: "Profissionais altamente qualificados.",
  },
];

const gradient = {
  background: "linear-gradient(270deg, #00FFF2 0%, #163EA5 100%)",
  WebkitBackgroundClip: "text",
  color: "transparent",
  WebkitTextFillColor: "transparent",
};

export const About = () => {
  return (
    <section className="w-full py-10 md:py-[49px] px-[24px] md:px-[50px] 2xl:justify-center lg:px-[80px] xl:px-[140px] 2xl:px-[130px] mx-auto lg:py-16 block md:flex items-center">
      <div className="w-full flex items-center justify-between gap-12  2xl:max-w-[1233px]">
        <div className="flex-1 h-full w-full flex flex-col md:items-center lg:items-start ">
          <h1 className="w-full text-[#130E50]  text-center  lg:text-left  md:w-[452px] lg:w-[300px] xl:w-[490px]   font-black text-[22px] sm:text-[24px] leading-[24px] md:text-[40px] xl:text-[64px] md:leading-[40px] xl:leading-[55px]">
            <span style={gradient}>Conecte-se! </span>
            Rápido e pessoal.
          </h1>
          <div className="w-full justify-center items-center flex lg:hidden py-6 md:py-8">
            <img
              src={aboutImg}
              className="w-[199.32px] md:w-[326px] xl:w-[361.77px] 2xl:w-[503.99px] h-auto"
            />
          </div>
          <div className="grid md:grid-cols-2 lg:pt-8 gap-8 md:gap-6 ">
            {data.map((item, index) => (
              <div
                key={index}
                className=" w-full  lg:max-w-[213px] xl:max-w-[244px] flex flex-col gap-1 md:gap-1.5 text-[#130E50] "
              >
                <img
                  src={item.img}
                  alt={item.title}
                  className="w-[18px] h-auto"
                />
                <p className="font-bold text-lg leading-6">{item.title}</p>
                <p className="font-semibold text-sm leading-5 text-black">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="w-full justify-center items-center hidden lg:flex flex-[0.7] xl:flex-1 ">
          <img
            src={aboutImg}
            className="w-[199.32px] md:w-[326px] xl:w-[361.77px] 2xl:w-[503.99px] h-auto"
          />
        </div>
      </div>
    </section>
  );
};
