import { useState } from "react";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="md:bg-[#00fff233] rounded-ee-3xl rounded-ss-3xl z-40">
      <div
        className={`max-w-screen-xl flex flex-col md:flex-wrap items-center md:justify-between mx-auto absolute top-3 md:top-0 right-1 md:right-0 md:relative ${
          isOpen ? "bg-[#13233E]" : "bg-transparent"
        } md:bg-transparent rounded-ee-3xl rounded-ss-3xl pt-1 md:pt-0 h-[240px] w-[114px] md:w-auto md:h-auto`}
      >
        <button
          onClick={toggleMenu}
          data-collapse-toggle="navbar-default"
          type="button"
          className=" inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-gray-900 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-default"
          aria-expanded={isOpen ? "true" : "false"}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } md:block  h-full w-auto flex items-center`}
          id="navbar-default"
        >
          <ul className=" h-full md:w-[439px] lg:w-[592px] xl:w-[608px] md:h-[48px] justify-center items-center px-4 gap-3 md:gap-0  font-medium flex flex-col text-white rounded-lg md:flex-row md:space-x-8 rtl:space-x-reverse space-y-2 md:space-y-0">
            <li className="w-full">
              <a
                onClick={toggleMenu}
                href="#home"
                className="text-center text-base font-bold leading-6 block text-white rounded px-2 md:px-0   "
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li className="w-full">
              <a
                onClick={toggleMenu}
                href="#services"
                className="text-center font-base font-bold leading-6 block text-white rounded px-2 md:px-0  "
              >
                Serviços
              </a>
            </li>
            <li className="w-full">
              <a
                onClick={toggleMenu}
                href="#download"
                className="text-center font-base font-bold leading-6 block text-white rounded px-2 md:px-0  "
              >
                Download
              </a>
            </li>
            <li className="w-full">
              <a
                onClick={toggleMenu}
                href="#contact"
                className="text-center font-base font-bold leading-6 block text-white rounded px-2 md:px-0  "
              >
                Contato
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
