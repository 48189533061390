import { useState, useEffect } from "react";

export const Form = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [investment, setInvestment] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleInputChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setInvestment(event.target.value);
  };

  useEffect(() => {
    const isValid =
      name !== "" &&
      phone !== "" &&
      email !== "" &&
      investment !== "" &&
      isChecked;
    setIsFormValid(isValid);
  }, [name, phone, email, investment, isChecked]);

  return (
    <form className="w-full md:w-auto flex flex-col items-center lg:items-start gap-2">
      <input
        value={name}
        onChange={handleInputChange(setName)}
        placeholder="Nome:"
        className="w-full md:w-[530px] lg:w-[448px] 2xl:w-[494px] h-8 2xl:h-10 px-2.5 rounded  bg-[#FFFFFF1A] text-[10px] md:text-xs xl:text-sm text-white placeholder:text-white font-medium"
      />
      <input
        value={phone}
        onChange={handleInputChange(setPhone)}
        placeholder="Telefone com DDD:"
        className="w-full md:w-[530px] lg:w-[448px] 2xl:w-[494px] h-8 2xl:h-10 px-2.5 rounded  bg-[#FFFFFF1A] text-[10px] md:text-xs xl:text-sm text-white placeholder:text-white font-medium"
      />
      <input
        value={email}
        onChange={handleInputChange(setEmail)}
        placeholder="E-mail:"
        className="w-full md:w-[530px] lg:w-[448px] 2xl:w-[494px] h-8 2xl:h-10 px-2.5 rounded  bg-[#FFFFFF1A] text-[10px] md:text-xs xl:text-sm text-white placeholder:text-white font-medium"
      />
      <select
        id="countries"
        value={investment}
        onChange={handleSelectChange}
        className="w-full md:w-[530px] lg:w-[448px] 2xl:w-[494px] h-8 2xl:h-10 px-1.5 rounded  bg-[#FFFFFF1A] text-[10px] md:text-xs xl:text-sm text-white font-medium border-none"
      >
        <option value="" className="text-black font-semibold hidden">
          Qual valor disponível para investimento?
        </option>
        <option
          value={1}
          className="text-black font-semibold text-[10px] w-full p-40"
        >
          Abaixo de R$ 10 mil
        </option>
        <option
          value={2}
          className="text-black font-semibold text-[10px] w-full p-40"
        >
          De R$ 10 mil a R$ 25 mil
        </option>
        <option
          value={3}
          className="text-black font-semibold text-[10px] w-full p-40"
        >
          De R$ 25 mil a R$ 50 mil
        </option>
        <option
          value={4}
          className="text-black font-semibold text-[10px] w-full p-40"
        >
          De R$ 50 mil a R$ 100 mil
        </option>
        <option
          value={5}
          className="text-black font-semibold text-[10px] w-full p-40"
        >
          Acima de R$ 100 mil
        </option>
      </select>
      <div className="flex items-end py-3 md:py-1">
        <input
          checked={isChecked}
          onChange={handleCheckboxChange}
          id="checked-checkbox"
          type="checkbox"
          className="w-4 h-4 text-gray-600 bg-gray-100 rounded-3xl border-gray-300  focus:ring-gray-500 cursor-pointer dark:focus:ring-gray-600  focus:ring-2"
        />
        <label
          htmlFor="checked-checkbox"
          className="ms-2 text-[10px] font-medium text-gray-300"
        >
          Eu concordo com a{" "}
          <a
            href="https://drive.google.com/file/d/1A4oSj7RdAf7alQUKPSpGDk4bX-OkhCC8/view"
            target="_blank"
            className="text-[#00FFF2] underline"
          >
            Política de Privacidade.
          </a>
        </label>
      </div>
      <button
        type="submit"
        disabled={!isFormValid}
        className="w-[173px] md:w-[321px] lg:w-[309px] xl:w-[331px] 2xl:w-[379px] bg-[#00FFF2] py-2 xl:py-3 rounded text-[#0C0A23] leading-6 text-base font-semibold disabled:opacity-50"
      >
        Enviar
      </button>
      <p className="text-[10px] text-gray-200 leading-none mt-3 md:mt-[-2px] text-center lg:text-left px-4 md:px-0">
        Não invista antes de ler as informações essenciais da oferta.
      </p>
    </form>
  );
};
