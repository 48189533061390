import { useState } from "react";
import logo from "../../assets/logo-ocean.png";
import { Form } from "../../components/form";
import { Navbar } from "../../components/navbar";

export const Header = () => {
  return (
    <section
      id="home"
      className="bg-background-header w-full items-center flex flex-col bg-cover bg-right-top mx-auto pt-6 md:pt-12 bg-no-repeat h-[664px] md:h-[779px] lg:h-[478px] xl:h-[529px] 2xl:h-[648px] px-[24px] md:px-[50px] lg:px-[80px] xl:px-[130px]"
    >
      <div className=" w-full 2xl:max-w-[1233px]">
        {" "}
        <div className="w-full flex justify-between items-center ">
          <div>
            <img
              src={logo}
              className="w-[107px] lg:w-[129px] h-[36px] xl:w-[173px] lg:h-[43px] xl:h-[58px]"
            />
          </div>
          <div>
            <Navbar />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row h-full justify-between ">
          <div className="w-full flex flex-col items-center lg:block text-center lg:text-left lg:w-[407px] xl:w-[447px] 2xl:w-[533px] h-full pt-10 md:pt-16 2xl:pt-32">
            <h1 className="w-full font-black text-[24px] leading-[28px] md:text-[36px] xl:text-[40px] md:leading-[48px] xl:leading-[56px] 2xl:text-5xl 2xl:leading-[58px] text-white">
              Investimentos com{" "}
              <span className="text-[#00FFF2]">rentabilidade</span> de até{" "}
              <span className="text-[#00FFF2]">20% a.a</span> para você!
            </h1>
            <p className="w-full md:w-[370px] lg:w-full  xl:font-normal text-[10px] md:text-sm xl:text-base 2xl:text-xl  leading-[14px] md:leading-[18px] lg:leading-[22px] xl:leading-6 text-[#9DD0FF] mt-2 md:mt-5">
              Conheça agora a empresa que está revolucionando o mercado de
              Crowdfunding no Brasil.
            </p>
          </div>
          <div className="flex flex-col justify-center items-center lg:items-start w-full lg:w-[460px] 2xl:w-[600px] md:gap-1.5 xl:gap-3 lg:p-4 xl:p-0 2xl:p-6 ">
            <h1 className="w-full font-black text-[16px]  md:text-[24px]  xl:text-[32px] 2xl:text-[36px] md:leading-6 text-white text-center lg:text-left">
              Torne-se um <span className="text-[#00FFF2]"> investidor.</span>
            </h1>
            <p className="w-full  text-[10px] md:text-sm 2xl:text-lg md:leading-6 text-white  mb-3 text-center lg:text-left">
              Preencha o formulário e seja um investidor Ocean.
            </p>
            <Form />
          </div>
        </div>
      </div>
    </section>
  );
};
