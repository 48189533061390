import { Button } from "../../components/button";

export const Offer = () => {
  return (
    <section className="bg-[#13233E] flex 2xl:justify-center h-[320px] md:h-[360px] lg:h-[394px] xl:h-[424px] 2xl:h-[535px] relative w-full px-[25px] md:px-[60px] lg:px-[80px] xl:px-[160px] mx-auto">
      <div className="w-full h-full flex flex-col justify-center items-center text-center  2xl:max-w-[1233px]">
        <h1 className="text-white font-extrabold text-[22px] leading-[27px]  md:text-[40px] lg:text-[48px] md:leading-[52px] lg:leading-[62px]  xl:leading-[52px] 2xl:text-[64px] 2xl:leading-[62px] max-w-[702px] lg:max-w-[792px]  2xl:max-w-[1103px]">
          Com a <span className="text-[#00FFF2]">Ocean </span> você tem
          rentabilidade de até 20% a.a
        </h1>
        <button className=" w-[161px] md:w-[228px] xl:w-[236px] bg-[#00FFF2] py-1 md:py-3 rounded text-[#0C0A23] leading-6 text-base font-semibold mt-3 md:my-4 xl:my-10">
          Quero saber mais
        </button>
      </div>
    </section>
  );
};
