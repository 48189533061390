import background from "../../assets/possibilities.webp";

export const Possibilities = () => {
  return (
    <section
      className="bg-no-repeat bg-cover bg-center md:bg-right-top h-[320px] md:h-[360px] lg:h-[424px] 2xl:h-[597px] relative md:py-10 lg:py-20 w-full px-[25px] md:px-[60px] lg:px-[80px] xl:px-[160px] "
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="w-full h-full flex justify-center items-center text-center">
        <h1 className=" text-white font-extrabold text-[16px] md:text-[36px] lg:text-[38px]  2xl:text-[48px] leading-[16px] md:leading-[48px] lg:leading-[55px]">
          Um oceano de oportunidades espera por você.
          {""} <br className="block md:hidden" />{" "}
          <span className="text-[#00FFF2]">Potencialize seus ganhos.</span>
        </h1>
      </div>
    </section>
  );
};
