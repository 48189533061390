import checkcyan from "../../assets/check-cyan.svg";

const data = [
  {
    title: "Alta rentabilidade",
    description:
      "Projetos e ativos que buscam atingir uma rentabilidade acima da média do mercado.",
  },
  {
    title: "Processo descomplicado",
    description:
      "Invista de forma simples e rápida podendo contar com o auxílio e suporte do nosso time.",
  },
  {
    title: "Segurança e transparência",
    description:
      "A supervisão e diretrizes da regulamentação vigente aliado à transparência da tecnologia blockchain trazem segurança no investimento.",
  },
  {
    title: "Sem taxas e tarifas",
    description:
      "Não cobramos taxas nem tarifas para depósito e saques, a nossa taxa  de intermediação já é cobrada direto do emissor.",
  },
];

export const Services = () => {
  return (
    <section
      id="services"
      className="w-full flex flex-col justify-center items-center mx-auto px-[25px] md:px-[60px] lg:px-[80px] xl:px-[160px] py-8 md:pb-24 md:pt-12 lg:py-16 2xl:py-20 bg"
    >
      <h1 className="w-[202px] md:w-[616px] xl:w-[816px] font-black text-[24px] md:text-[38px] xl:text-[48px] leading-[24px] md:leading-[48px] xl:leading-[64px] text-center  text-[#13233E] pb-6 md:pb-12">
        Maximize seus investimentos com a Ocean.
      </h1>

      <div className="grid md:grid-cols-2 gap-6  place-items-center	 ">
        {data.map((item, index) => (
          <div
            key={index}
            className=" text-[#13233E] flex flex-col gap-2 w-full md:w-[326px] lg:w-[326px] xl:w-[354px] 2xl:w-[400px]"
          >
            <img
              src={checkcyan}
              alt={item.title}
              className="w-5 md:w-6 h-auto "
            />

            <p className="font-bold text-lg lg:text-xl leading-7">
              {item.title}
            </p>
            <p className="font-normal text-sm lg:text-base leading-6">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};
