/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvelopeSimple, InstagramLogo, WhatsappLogo } from "phosphor-react";
import logo from "../../assets/logo-ocean.png";
import logo2x from "../../assets/logo-ocean-2x.png";
import logo3x from "../../assets/logo-ocean-3x.png";
import logo4x from "../../assets/logo-ocean-4x.png";
import regulatory from "../../assets/regulatory.svg";

export const Footer = ({ bank }: any) => {
  return (
    <footer
      id="contact"
      style={{
        background:
          "linear-gradient(105.07deg, #140F51 61.97%, #163A9D 99.96%)",
      }}
      className="w-full py-10 2xl:flex md:py-[49px] px-[24px] md:px-[50px]  lg:px-[80px]  xl:px-[140px] 2xl:px-[130px] mx-auto lg:pt-8 2xl:justify-center"
    >
      <div className="flex flex-col 2xl:max-w-[1233px]">
        <div className="grid md:grid-cols-2 md:place-content-center items-center  ">
          <div className="w-[128px] md:w-[122px] lg:w-[162px] h-[44px] md:h-[42px] lg:h-[52px]">
            <img
              src={logo}
              className="w-full h-full"
              srcSet={`${logo2x} 2x, ${logo3x} 3x, ${logo4x} 4x,`}
              alt="Logo"
            />
          </div>
          <hr className="border-0 block md:hidden h-[1px] lg:h-[2px] w-full bg-[#00FFF2] my-6 opacity-20" />

          <div className="flex flex-col text-startpl-10 lg:pl-10 2xl:pl-20">
            <p className=" text-[10px] leading-[18px] lg:text-xs text-[#D9D9D9] font-medium lg:leading-6  pb-1.5 2xl:pb-4">
              Reguladora
            </p>
            <img
              className="h-[41.09px] w-[88.03px] lg:h-[58.11px] lg:w-[125.03px]"
              src={regulatory}
              alt="Logo"
            />
          </div>
        </div>
        <hr className="border-0 h-[1px] lg:h-[2px] w-full bg-[#00FFF2] my-6 opacity-20" />
        <div className="grid md:grid-cols-2">
          <div>
            <div className="flex gap-4 mb-4">
              <a
                target="_blank"
                className="w-10 h-10 lg:w-12 lg:h-12 flex justify-center items-center rounded-[4px] bg-white text-[#13233E]"
              >
                <InstagramLogo className="w-[28.55px] lg:w-[33.95px] h-auto" />
              </a>
              <a
                href={`https://wa.me/5551995197234?text=`}
                target="_blank"
                className="w-10 h-10 lg:w-12 lg:h-12 flex justify-center items-center rounded-[4px] bg-white text-[#13233E]"
              >
                <WhatsappLogo className="w-[28.55px] lg:w-[33.95px] h-auto" />
              </a>
              <a
                href={`mailto:${bank?.email}`}
                className="w-10 h-10 lg:w-12 lg:h-12 flex justify-center items-center rounded-[4px] bg-white text-[#13233E]"
              >
                <EnvelopeSimple className="w-[28.55px] lg:w-[33.95px] h-auto" />
              </a>
            </div>

            <div className=" text-white text-sm font-bold leading-5">
              <p className="font-semibold text-lg leading-6 text-[#00FFF2]">
                Endereço
              </p>
              <p className="font-normal text-sm xl:text-base leading-6 text-[#D9D9D9] mt-1">
                Av. Taquara, 335 - Bairro Petrópolis Porto Alegre/RS - CEP
                90460-210
              </p>
            </div>
          </div>
          <hr className="border-0 block md:hidden h-[1px] lg:h-[2px] w-full bg-[#00FFF2] my-6 opacity-20" />
          <div className="flex gap-6 md:gap-16 2xl:gap-24 lg:pl-10 2xl:pl-20">
            <div className="flex flex-col  gap-1">
              <p className="font-semibold text-xs lg:text-base leading-6 text-[#00FFF2]">
                Legal
              </p>
              <div className=" flex flex-col gap-1.5 font-normal text-[10px] lg:text-sm leading-[24px] text-[#D9D9D9]">
                <a
                  href="https://drive.google.com/file/d/1A4oSj7RdAf7alQUKPSpGDk4bX-OkhCC8/view"
                  className="hover:text-gray-300 underline"
                  target="_blank"
                >
                  Política de Privacidade
                </a>
                <a
                  href="https://drive.google.com/file/d/1iKJW0XBx_eZs6rRhZbv23pexOmWxPwgE/view"
                  className="hover:text-gray-300 underline"
                  target="_blank"
                >
                  Material Código de Conduta
                </a>
                <a
                  href="https://drive.google.com/file/d/14JfalN0Rq8O-gd_3353gsyLrL3XUk3A8/view"
                  className="hover:text-gray-300 underline"
                  target="_blank"
                >
                  Termos de Uso
                </a>
              </div>
            </div>
            <div className="flex flex-col  gap-1">
              <p className="font-semibold text-xs lg:text-base leading-6 text-[#00FFF2]">
                CVM 88/2022
              </p>
              <div className=" flex flex-col gap-1.5 font-normal  text-[10px] lg:text-sm leading-[24px] text-[#D9D9D9]">
                <a
                  href="https://drive.google.com/file/d/1A4oSj7RdAf7alQUKPSpGDk4bX-OkhCC8/view"
                  className="hover:text-gray-300 underline"
                  target="_blank"
                >
                  Autorização CVM{" "}
                </a>
                <a
                  href="https://drive.google.com/file/d/1iKJW0XBx_eZs6rRhZbv23pexOmWxPwgE/view"
                  className="hover:text-gray-300 underline"
                  target="_blank"
                >
                  Material Didático{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-0 h-[1px] lg:h-[2px] w-full bg-[#00FFF2] my-3 opacity-20" />

        <p className=" mt-4 font-normal leading-[12px] lg:leading-[16px] text-[10px] lg:text-xs text-[#D9D9D9] text-justify 2xl:text-left	">
          As sociedades empresárias de pequeno porte e as ofertas apresentadas
          nesta plataforma estão automaticamente dispensadas de registro pela
          Comissão de Valores Mobiliários - CVM. A CVM não analisa previamente
          as ofertas. As ofertas realizadas não implicam por parte da CVM a
          garantia da veracidade das informações prestadas, de adequação à
          legislação vigente ou julgamento sobre a qualidade da sociedade
          empresária de pequeno porte. Antes de aceitar uma oferta leia com
          atenção as informações essenciais da oferta, em especial a seção de
          alertas sobre risco e o material didático do investidor.
        </p>
      </div>
    </footer>
  );
};
