import evolutionImg from "../../assets/evolution.webp";

export const Evolution = () => {
  return (
    <section className="w-full py-10 md:py-[49px] mx-auto lg:py-16 flex items-center  2xl:justify-center px-[24px] md:px-[50px] lg:px-[80px] xl:px-[140px] 2xl:px-[130px]">
      <div className="w-full flex flex-col lg:flex-row items-center justify-center  xl:gap-16 2xl:gap-24 2xl:max-w-[1233px]">
        <div className="w-full flex ">
          <img
            src={evolutionImg}
            className="hidden lg:block w-[200px] md:w-[442px] lg:w-[326px] xl:w-[362px] 2xl:w-[504px]  h-auto"
          />
        </div>
        <div className="w-full  flex flex-col items-center lg:block ">
          <h1 className="text-center lg:text-left  text-[#130E50] font-black text-[24px] md:text-[40px] xl:text-[48px] 2xl:text-[64px] leading-[24px] md:leading-[40px] xl:leading-[55px] lg:pb-4 w-[200px] md:w-[400px] lg:w-full">
            Evolução <br className="hidden lg:block" /> do{" "}
            <span className="gradient-text-two">mercado finaceiro</span>
          </h1>
          <div className="flex-1">
            <img
              src={evolutionImg}
              className="block lg:hidden w-[200px] md:w-[442px] lg:w-[326px] xl:w-[362px] 2xl:w-[504px]  h-auto my-8"
            />
          </div>
          <p className="text-[#130E50] font-medium text-[11px] md:text-base xl:text-lg leading-[20px] md:leading-[24px] 2xl:leading-[31px] text-center lg:text-left">
            O Mercado de Capitais se desenvolve e evolui, você também precisa se
            adaptar e aproveitar as melhores oportunidades. Estamos em constante
            crescimento gerando resultados significativos e positivos para
            nossos clientes ano após ano.
          </p>
        </div>
      </div>
    </section>
  );
};
