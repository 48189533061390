import apps from "../../assets/apps.webp";
import qrCodeApple from "../../assets/qrcode-apple.png";
import qrCodeGoogle from "../../assets/qrcode-googleplay.png";
import appStore from "../../assets/app-store.png";
import googlePlay from "../../assets/google-play.png";

export const Apps = () => {
  return (
    <section
      id="download"
      className="bg-hero-pattern bg-no-repeat bg-cover h-[701px] flex 2xl:justify-center md:h-[560px] lg:h-[660px] 2xl:h-[780px] relative w-full px-[24px] md:px-[50px] lg:px-[80px] xl:px-[140px] 2xl:px-[130px]"
    >
      <div className="w-full h-full flex items-center gap-4 lg:gap-16 xl:gap-24 2xl:max-w-[1233px]">
        <div className="w-full hidden md:flex lg:justify-end items-center">
          <img
            src={apps}
            className="w-auto h-[359px] md:h-[402px] lg:h-[492px] xl:h-[502px] 2xl:h-[627px]"
          />
        </div>
        <div className="w-full h-full  flex flex-col gap-3 items-start justify-center">
          <h1 className="text-center md:text-left text-white font-black text-[32px]  md:text-[40px] lg:text-[48px] leading-[32px] md:leading-[40px] lg:leading-[56px] max-w-[429px]">
            Um <span className="text-[#00FFF2]">oceano de oportunidades</span>{" "}
            no seu bolso.
          </h1>
          <p className="text-center pt-1 md:pt-0 md:text-left  text-sm lg:text-base xl:text-xl leading-5 lg:leading-6 text-white max-w-[469px]">
            Controle sua conta, faça gerenciamento de tokens, consulte a sua
            carteira digital e muito mais!
          </p>
          <div className="w-full flex md:hidden justify-center items-center  pt-10">
            <img
              src={apps}
              className="w-auto h-[329px] md:h-[402px] lg:h-[492px] xl:h-[502px] 2xl:h-[627px]"
            />
          </div>
          <div className="w-full">
            <div className="w-full flex gap-4 md:gap-14 lg:gap-28 xl:gap-20 py-2 justify-center md:justify-normal md:px-4 md:py-8 lg:p-8 ">
              <div className="w-[72px] md:w-[108px] lg:w-[110px] xl:w-[128px] 2xl:w-[104px]">
                <img
                  src={qrCodeApple}
                  className="w-full h-[110px] lg:h-[113px] xl:h-[131px] 2xl:h-[106px] rounded-lg hidden md:block"
                />
                <img
                  src={appStore}
                  className="w-full h-[25px] md:h-[36.6px] xl:h-[40.72px] 2xl:h-[35.98px] rounded mt-2"
                />
              </div>
              <div className="w-[72px] md:w-[108px] lg:w-[110px] xl:w-[128px] 2xl:w-[104px]">
                <img
                  src={qrCodeGoogle}
                  className="w-full h-[110px] lg:h-[113px] xl:h-[131px] 2xl:h-[106px] rounded-lg hidden md:block"
                />
                <img
                  src={googlePlay}
                  className="w-full h-[25px] md:h-[36.6px] xl:h-[40.72px] 2xl:h-[35.98px] rounded mt-2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
